import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";

import $BGEM from "../../assets/images/daily/Diamond.svg";
import { PassportProvider } from "../../context/PassportProvider";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import { useSearchParams } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

const TasksPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentUrl = window.location.href;
  const playerId = currentUrl.split("id=")[1];

  const gameId = searchParams.get("gameId");

  const getTasks = async () => {
    try {
      const id = playerId || getCookie("playerId");

      const response = await axios.get(
        `https://api-testnet.boomland.io/api/game/task/${gameId}`,
        { headers: { Authorization: id } }
      );

      const { tasks } = response.data;
      setTasks(tasks);
    } catch (e) {
      console.error(e);
      toast.error("Internal Server Error, please try again later.");
    }
  };

  const claimTask = async (taskId) => {
    const id = playerId || getCookie("playerId");

    try {
      setIsLoading(true);
      await axios.post(
        `https://api-testnet.boomland.io/api/game/task/${taskId}`,
        null,
        { headers: { Authorization: id } }
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      console.error(e);
      toast.error("Internal Server Error, please try again later.");
    }
  };

  useEffect(() => {
    if (!playerId) return;

    getTasks();
  }, []);

  return (
    <div className="container-x h-full">
      <Toaster />
      <div className="sortbar">
        <h3>Tasks</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 sm:w-[480px] sm:h-[640px] self-center">
          <div className="defi-cards-bg mt-2 sm:w-[480px] sm:min-h-[320px] overflow-clip">
            <span>Tasks</span>
            <div className="flex flex-col justify-center mt-4 overflow-y-auto">
              {tasks.map((task, index) => (
                <div
                  className="flex flex-row justify-between bg-black rounded-md p-2 my-1"
                  key={index}
                >
                  <span className="self-center text-xs w-[144px]">
                    {task.name}
                  </span>
                  <div className="flex flex-row">
                    <img
                      src={$BGEM}
                      alt="bgem"
                      className="w-[20px] self-center"
                    />
                    <span className="self-center ml-1 text-sm">
                      {task.reward}
                    </span>
                  </div>
                  <div className="flex flex-row rounded-md p-1 text-xs self-center">
                    {`${task.progress}/${task.required}`}
                  </div>
                  {task.claimed ? (
                    <button
                      className="rounded-md bg-green-700 p-2 px-3 text-xs shadow-lg shadow-black"
                      disabled
                    >
                      CLAIMED
                    </button>
                  ) : (
                    <button
                      className={`rounded-md ${
                        task.required >= task.progress
                          ? "bg-gray-600"
                          : "bg-green-400 hover:bg-green-300"
                      }  p-2 px-3 text-xs shadow-lg shadow-black`}
                      onClick={async () => {
                        await claimTask(task.id);
                        await getTasks();
                      }}
                      disabled={task.required >= task.progress}
                    >
                      {isLoading ? (
                        <ClipLoader css={spinnerCss} size={16} color={"#fff"} />
                      ) : (
                        "CLAIM"
                      )}
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Tasks = () => (
  <PassportProvider>
    <TasksPage />
  </PassportProvider>
);
export default Tasks;
