import "@rainbow-me/rainbowkit/styles.css";

import React, { useEffect, useState } from "react";

import { BigNumber, Contract } from "ethers";
import $BGEM from "../../assets/images/daily/Diamond.svg";
import { immutableConfig } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { PassportProvider, usePassport } from "../../context/PassportProvider";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const spinnerCss = css`
  display: block;
  margin: 0 auto;
`;

const DIV = "1000000000000000000";

const RedeemPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [bgemBalance, setBgemBalance] = useState(0);

  const { connect, signer, address } = usePassport();

  const currentUrl = window.location.href;
  const playerId = currentUrl.split("id=")[1];

  const updateBgemBalance = async () => {
    const response = await axios.get(
      `https://api-testnet.boomland.io/api/user/`,
      { headers: { Authorization: playerId } }
    );
    const balance = response?.data?.balance || 0;
    setBgemBalance(balance);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const redeem = async () => {
    try {
      setIsLoading(true);

      const redeemContract = new Contract(
        immutableConfig.contracts.redeem.address,
        immutableConfig.contracts.redeem.abi,
        signer
      );

      const response = await axios.post(
        `https://api-testnet.boomland.io/api/user/redeem/`,
        {
          wallet: address,
        },
        { headers: { Authorization: playerId } }
      );

      const signature = response.data.signature;

      const nonce = await redeemContract.nonces(address);

      const tx = await redeemContract.redeem(
        {
          amount: BigNumber.from(bgemBalance).mul(DIV),
          wallet: address,
          nonce,
        },
        signature,
        {
          gasLimit: 30000000,
        }
      );
      await tx.wait(1);
      await updateBgemBalance();
      setIsLoading(false);
      toast.success("Success! You redeemed your bgem");
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      toast.error(
        "An Error happened, please make sure you have enough $IMX in your wallet and try again."
      );
    }
  };

  useEffect(() => {
    if (address) {
      updateBgemBalance();
    }
  }, [address]);

  return (
    <div className="container-x h-full">
      <Toaster />
      <div className="sortbar">
        <h3>Redeem</h3>
      </div>
      <span className="border-b" />
      <div className="mt-30 flex flex-col justify-center">
        <div className="h-screen w-full px-2 sm:w-[480px] sm:h-[640px] self-center">
          <button
            onClick={connect}
            diisabled={!!address}
            className="bg-[#f6a552] shadow-sm shadow-[#00d200] hover:bg-green-600 rounded-lg py-1 text-md font-bold mb-1 px-2"
          >
            {address
              ? `${address.slice(0, 5)}...${address.slice(
                  address.length - 5,
                  address.length
                )}`
              : "Connect Wallet"}
          </button>
          <div className="defi-cards-bg mt-2 sm:w-[480px] sm:min-h-[320px] overflow-clip">
            {!address && (
              <div className="flex flex-col justify-center self-center h-full">
                <span className="text-xl text-center self-center">
                  Connect your Wallet to continue
                </span>
              </div>
            )}

            {address && (
              <div className="flex flex-col">
                <h1 className="text text-2xl text-center">You have</h1>
                <div className="flex flex-row justify-center self-center my-4 p-2 bg-[#2d1c35] rounded-lg bg-opacity-10">
                  <span className="text-6xl font-bold mr-3 text-center">
                    {" "}
                    {numberWithCommas(bgemBalance)}{" "}
                  </span>
                  <img src={$BGEM} alt="bgem" className="h-[64px]" />
                </div>
                {/* <h1 className="text text-2xl text-center">to Redeem</h1> */}

                <button
                  className={`mt-4 w-full ${
                    bgemBalance <= 0 || !bgemBalance
                      ? "bg-gray-500 rounded-md py-2"
                      : "buy-btn-defi"
                  }`}
                  onClick={redeem}
                  disabled={bgemBalance <= 0 || !bgemBalance}
                >
                  <div>
                    {isLoading ? (
                      <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
                    ) : (
                      "REDEEM"
                    )}
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Redeem = () => (
  <PassportProvider>
    <RedeemPage />
  </PassportProvider>
);
export default Redeem;
