/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/home/logo.svg";

// wallet modal
import { useSequenceWalletConnect } from "../context";
import { SequenceLinkWalletModal } from "./Modal/LinkWalletModal";
import { shortenAddress } from "../helpers/functions.helper";
//store
import { useSelector } from "react-redux";
import { getCookie, setCookie } from "typescript-cookie";
import { Chip, Stack, Switch, styled } from "@mui/material";
import { Link } from "react-router-dom";
import BoomIcon from "../assets/images/$BOOM_Small.png";
import imxLogo from "../assets/images/passport_logo_64px.svg";
import polygonLogo from "../assets/images/polygon_logo.svg";

const NetworkSwitch = styled(Switch)(({ theme }) => ({
  padding: 6,
  width: 54,
  height: 32,
  marginTop: "3px !important",
  marginLeft: "13px !important",
  "& .Mui-checked": {
    transform: "translateX(20px)",
    color: "white !important",
    "& + .MuiSwitch-track": {
      border: "1px solid white !important",
    },
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#2a1136 !important",
    border: "1px solid white !important",
  },
  "& .MuiSwitch-switchBase": {
    padding: 5,
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    border: "1px solid white !important",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 22,
    height: 22,
    margin: 0,
  },
}));

function Header({ disableBoomBalance }) {
  const ref = useRef();
  const { walletAddress, isWalletConnected, boomBalance } =
    useSequenceWalletConnect();
  const [linkWalletModal, setLinkWalletModal] = useState(false);
  const [isMainnet, setNetwork] = useState(true);
  const [chestToggle, setChestToggle] = useState(false);

  const pendingHuntersCount = useSelector(
    (state) => state.Items.pendingHunters
  );
  const pendingHuntersStyle = {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "red",
    textAlign: "center",
    alignSelf: "center",
  };

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  function navToggle() {
    var $nav_header = document.getElementById("nav-header");

    if ($nav_header.getAttribute("data-toggle") === "false") {
      $nav_header.setAttribute("data-toggle", "true");
      document.getElementById("header").style.backgroundColor =
        "rgba(0, 0, 0, 0.7)";
      document.getElementById("nav-container").style.display = "block";
      $nav_header.classList.remove("d-none");
      $nav_header.classList.add("d-block");
      document.getElementById("btn-golden-ticket-qa")?.classList.add("hidden");
      document.getElementById("btn-connect-wallet-qa")?.classList.add("hidden");
      document.querySelector(".header-nav-item i").classList.add("rotate");
    } else {
      $nav_header.setAttribute("data-toggle", "false");
      document.getElementById("nav-container").style.display = "none";
      document.getElementById("header").style.backgroundColor = "transparent";
      $nav_header.classList.remove("d-block");
      $nav_header.classList.add("d-none");
      document
        .getElementById("btn-golden-ticket-qa")
        ?.classList.remove("hidden");
      document
        .getElementById("btn-connect-wallet-qa")
        ?.classList.remove("hidden");
      document.querySelector(".header-nav-item i").classList.remove("rotate");
    }
  }

  const linkWalletToggle = () => {
    setLinkWalletModal(!linkWalletModal);
  };

  useEffect(() => {
    function handler() {
      if (window.scrollY > 100) {
        ref.current.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      } else if (
        document.getElementById("nav-header").getAttribute("data-toggle") ===
        "false"
      ) {
        ref.current.style.backgroundColor = "transparent";
      }
    }
    window.addEventListener("scroll", handler);

    return () => {
      window.removeEventListener("scroll", handler);
    };
  });

  useEffect(() => {
    window.addEventListener("load", () => {
      if (window.location.pathname === "/" && window.location.hash) {
        window.location.href = window.location.hash;
      }
    });
  }, []);

  return (
    <header id="header" className="header" ref={ref}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <Stack direction="row" spacing={2}>
              <Stack
                direction="column"
                alignItems="flex-end"
                justifyContent="center"
              >
                <a
                  href="/"
                  className="header-logo-link d-inline-block py-1 py-lg-0"
                >
                  <img className="header-logo-image" src={logo} alt="Logo" />
                </a>
                <Chip
                  className="network-chip"
                  label={isMainnet ? "MAINNET" : "TESTNET"}
                  size="small"
                  color={isMainnet ? "success" : "error"}
                />
              </Stack>
            </Stack>
          </div>
          <div className="col col-md-8 d-xl-none">
            <nav
              id="header-nav"
              className="header-nav d-flex justify-content-end align-items-center"
            >
              {isWalletConnected && !disableBoomBalance && (
                <div className="flex flex-row justify-center relative ml-1 flex md:hidden">
                  <img
                    src={BoomIcon}
                    className="h-10 w-10 l-10 z-10 absolute left-[-15px] top-[-5px]"
                  />
                  <div className="flex flex-col justify-center z-0 mr-2 w-32">
                    <div className="font-bold px-10 text-lg ml-2 text-end flex justify-end truncate w-full rounded-md bg-[#300a3c] border-1 border-[#462350]">
                      <span className="self-end text-end flex w-full justify-center">
                        {nFormatter(boomBalance, 2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* {!isWalletConnected && (
                <button
                  id="btn-connect-wallet-qa"
                  className="header-nav-item d-none d-md-inline-block cta-wallet cta me-2 "
                  type="button"
                  onClick={connectWallettogal}
                >
                  Connect Wallet
                </button>
              )} */}
              {isWalletConnected && (
                <button
                  id="btn-connect-wallet-qa"
                  className="header-nav-item d-none d-md-inline-block cta-wallet cta me-2 "
                  type="button"
                  onClick={linkWalletToggle}
                >
                  {walletAddress.substring(0, 16)}...
                </button>
              )}
              <button
                id="nav-toggle"
                className="header-nav-item cta"
                data-target="nav-header"
                onClick={navToggle}
              >
                <i className="fa-solid fa-bars" />
              </button>
            </nav>
          </div>

          <div
            className="col-12 mt-4 mt-xl-0 col-xl  z-index-9000"
            id="nav-container"
          >
            <nav
              id="nav-header"
              className="d-none d-xl-flex w-100 justify-content-end justify-content-md-center justify-content-xl-end align-items-center header-nav"
              data-toggle="false"
            >
              <button
                id="btn-open-chests"
                className="header-nav-item cta-golden cta relative"
                type="button"
                onClick={() => setChestToggle(!chestToggle)}
              >
                Open&nbsp;Chests
                {chestToggle && (
                  <div
                    className="absolute top-8 left-0 flex flex-col justify-center self-center bg-[#551f69] rounded-md p-2 bg-opacity-80 w-[120px]"
                    style={{ display: "hidden" }}
                  >
                    <button
                      id="btn-open-chests"
                      className="mb-2 flex flex-row justify-start"
                      type="button"
                      onClick={() =>
                        (window.location.href = "/open-chests-imx")
                      }
                    >
                      <img
                        className="w-[18px] self-center mr-1"
                        src={imxLogo}
                        alt="Logo"
                      />
                      <span>Immutable</span>
                    </button>
                    <button
                      id="btn-open-chests"
                      className="mb-2 flex flex-row justify-start"
                      type="button"
                      onClick={() => (window.location.href = "/open-chests")}
                    >
                      <img
                        className="w-[26px] self-center mr-1"
                        src={polygonLogo}
                        alt="Logo"
                      />
                      <span>Polygon</span>
                    </button>
                  </div>
                )}
              </button>

              <a className="header-nav-item position-relative" href="/daily">
                Token&nbsp;Claim
              </a>

              <a
                className="header-nav-item"
                href="https://wp.boomland.io/boomland"
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </a>
              <a className="header-nav-item header-nowrap" href="/my-assets">
                My Assets
              </a>

              <Link className="header-nav-item header-nowrap" to="/rent">
                <p>Rent</p>
              </Link>

              <Link className="header-nav-item header-nowrap" to="/swap">
                <p>Swap</p>
              </Link>

              {/* Add a circle with css */}
              {pendingHuntersCount > 0 && (
                <div style={pendingHuntersStyle}>{pendingHuntersCount}</div>
              )}
              <div className="header-nav-separator" />
              {/* <a className="header-nav-item" href="/mint">
                Mint
              </a> */}

              {isWalletConnected && !disableBoomBalance && (
                <div className="flex flex-row justify-center relative ml-8 hidden md:flex mr-2">
                  <img
                    src={BoomIcon}
                    className="h-10 w-10 l-10 z-10 absolute left-[-15px] top-[-5px]"
                  />
                  <div className="flex flex-col justify-center z-0 mr-2 w-32">
                    <div className="font-bold px-10 text-lg ml-2 text-end flex justify-end truncate w-full rounded-md bg-[#300a3c] border-1 border-[#462350]">
                      <span className="self-end text-end flex w-full justify-center">
                        {nFormatter(boomBalance, 2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {!isWalletConnected && (
                <button
                  id="btn-connect-wallet"
                  className="header-nav-item cta-wallet cta"
                  type="button"
                  onClick={async () => {
                    linkWalletToggle();
                  }}
                >
                  Connect Wallet
                </button>
              )}

              {isWalletConnected && (
                <button
                  id="btn-connect-wallet"
                  className="header-nav-item cta-wallet cta"
                  type="button"
                  onClick={linkWalletToggle}
                >
                  {shortenAddress(walletAddress)}
                </button>
              )}
            </nav>
          </div>
        </div>
      </div>

      <SequenceLinkWalletModal
        isOpened={linkWalletModal}
        toggleModal={linkWalletToggle}
      ></SequenceLinkWalletModal>
    </header>
  );
}

export default Header;
