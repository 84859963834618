import { SequenceIndexerClient } from "@0xsequence/indexer";
import { CONTRACT_ADDRESSES, CHAIN_ID } from "./chainConfig";
import axios from "axios";
import { immutableConfig } from "../config";

export async function getChestItems(account, imx) {
  if (imx) {
    const balance = await get(`accounts/${account}/nfts`, {
      contract_address: immutableConfig.genesisTier3,
    });
    const items = balance.map((b) => b.token_id);

    return {
      tier3_items: items,
      tier3_length: balance.length,
      tier1_items: [],
      tier1_length: 0,
      tier2_items: [],
      tier2_length: 0,
    };
  }

  const indexerUrl =
    CHAIN_ID === 137
      ? "https://polygon-indexer.sequence.app"
      : "https://mumbai-indexer.sequence.app";

  const indexer = new SequenceIndexerClient(indexerUrl);

  // We could handle pagination of the indexer but page size is large enough that we don't care
  const indexerResponse = await indexer
    .getTokenBalances({
      contractAddress: CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN,
      accountAddress: account,
    })
    .then((response) => response.balances);

  const tier2IndexerResponse = await indexer
    .getTokenBalances({
      contractAddress: CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN_TIER2,
      accountAddress: account,
    })
    .then((response) => response.balances);

  return {
    tier1_length: indexerResponse.length,
    tier1_items: indexerResponse.map((balance) => parseInt(balance.tokenID)),
    tier2_length: tier2IndexerResponse.length,
    tier2_items: tier2IndexerResponse.map((balance) =>
      parseInt(balance.tokenID)
    ),
    tier3_items: [],
    tier3_length: 0,
  };
}

const get = async (path, params) => {
  const indexerUrl = immutableConfig.apiBaseUrl;
  const chainName = immutableConfig.chainName;

  const response = await axios.get(
    `${indexerUrl}/v1/chains/${chainName}/${path}`,
    {
      params: { ...params, page_size: 200 },
    }
  );

  let result = [];

  const data = response.data;

  let nextCursor = data.page?.next_cursor;

  result = result.concat(data.result);

  while (nextCursor) {
    const nextPageResponse = await axios.get(
      `${indexerUrl}/v1/chains/${chainName}/${path}`,
      {
        params: { ...params, page_cursor: nextCursor, page_size: 200 },
      }
    );
    const nextData = nextPageResponse.data;

    nextCursor = nextData.page?.next_cursor;
    result = result.concat(nextData.result);
  }

  return result;
};
