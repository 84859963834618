import { passport as imtblPassport } from "@imtbl/sdk";
import { ethers } from "ethers";
import { createContext, useContext, useEffect, useState } from "react";
import { immutableConfig } from "../config";

let passportInstance;

const PassportContext = createContext(null);

export const PassportProvider = ({ children }) => {
  const [address, setAddress] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [passport, setPassport] = useState(null);
  const [signer, setSigner] = useState(null);

  const getPassport = () => {
    if (passportInstance) return passportInstance;

    const options = {
      baseConfig: {
        environment: immutableConfig.environment,
        publishableKey: immutableConfig.publishableKey,
      },
      clientId: immutableConfig.clientId,
      redirectUri: `${immutableConfig.passportUrl}/callback`,
      logoutRedirectUri: `${immutableConfig.passportUrl}/callback`,
      audience: "platform_api",
      scope: "openid offline_access email transact",
      logoutMode: "silent",
    };

    passportInstance = new imtblPassport.Passport(options);

    return passportInstance;
  };

  const getSigner = async () => {
    if (!passport) return;

    const provider = passport.connectEvm();
    const web3Provider = new ethers.providers.Web3Provider(provider);
    const wallet = await web3Provider.getSigner();
    setSigner(wallet);

    return wallet;
  };

  const connect = async () => {
    if (!passport) return;
    const provider = passport.connectEvm();
    const [account] = await provider.request({ method: "eth_requestAccounts" });

    const addr = ethers.utils.getAddress(account);
    setAddress(addr);

    await getSigner();
  };

  const disconnect = async () => {
    if (!passport) return;

    await passport.logout();
    setAddress("");
  };

  useEffect(() => {
    const passportImx = getPassport();
    setPassport(passportImx);
  }, []);

  useEffect(() => {
    const getConnected = async () => {
      if (!passport) return;

      const token = await passport.getIdToken();
      if (token) await connect(token);
    };

    if (!passport) return;
    getConnected();
  }, [passport]);

  return (
    <PassportContext.Provider
      value={{
        address,
        signer,
        connect,
        disconnect,
        isConnected,
        passport,
      }}
    >
      {children}
    </PassportContext.Provider>
  );
};

export const usePassport = () => useContext(PassportContext);
